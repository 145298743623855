import React, { useState } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { useSpring, animated } from '@react-spring/web';
import { useInView } from 'react-intersection-observer';

function AnimatedNumbers() {
    const [trigger, setTrigger] = useState(false);

    // Use the Intersection Observer to detect when the component is in view
    const [ref, inView] = useInView({
        triggerOnce: true,  // Trigger animation only once
        threshold: 0.2,     // 20% of the component needs to be visible before triggering
    });

    // When the component comes into view, trigger the animation
    if (inView && !trigger) {
        setTrigger(true);
    }

    // Animating the numbers for each section
    const numberAnimation1 = useSpring({
        from: { number: 0 },
        number: trigger ? 1000000 : 0,
        config: { duration: 3000 },
    });

    const numberAnimation2 = useSpring({
        from: { number: 0 },
        number: trigger ? 4000000 : 0,
        config: { duration: 3000 },
    });

    const numberAnimation3 = useSpring({
        from: { number: 0 },
        number: trigger ? 2000000 : 0,
        config: { duration: 3000 },
    });

    return (
        <Box sx={{ padding: '4rem 2rem' }} ref={ref}> {/* Attach the ref for Intersection Observer */}
            <Grid container spacing={4} justifyContent="center" alignItems="center">
                {/* Left Column */}
                <Grid item xs={12} md={4}>
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography
                            variant="h6"
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '3rem',
                                padding: '0.5rem 1rem',
                                border: '1px solid #000',
                                borderRadius: '20px',
                                display: 'inline-block',
                                marginBottom: '1rem',
                                color: '#FF4500'
                            }}
                        >
                            Blake's Business
                        </Typography>
                        <Typography
                            variant="h2"
                            sx={{
                                fontSize: '2.5rem',
                                fontWeight: 'bold',
                            }}
                        >
                            <animated.span>
                                {numberAnimation1.number.to((n) => `$${(n / 1000000).toFixed(1)}M`)}
                            </animated.span>
                        </Typography>
                    </Box>
                </Grid>

                {/* Right Column */}
                <Grid item xs={12} md={4}>
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography
                            variant="h6"
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '3rem',
                                padding: '0.5rem 1rem',
                                border: '1px solid #000',
                                borderRadius: '20px',
                                display: 'inline-block',
                                marginBottom: '1rem',
                                color: '#FF4500'
                            }}
                        >
                            Eric's Business
                        </Typography>
                        <Typography
                            variant="h2"
                            sx={{
                                fontSize: '2.5rem',
                                fontWeight: 'bold',
                            }}
                        >
                            <animated.span>
                                {numberAnimation3.number.to((n) => `$${(n / 1000000).toFixed(1)}M`)}
                            </animated.span>
                        </Typography>
                    </Box>
                </Grid>

                {/* Middle Column */}
                <Grid item xs={12} md={4}>
                    <Box sx={{ textAlign: 'center' }}>
                        <Typography
                            variant="h6"
                            sx={{
                                fontWeight: 'bold',
                                fontSize: '3rem',
                                padding: '0.5rem 1rem',
                                border: '1px solid #000',
                                borderRadius: '20px',
                                display: 'inline-block',
                                marginBottom: '1rem',
                                color: '#FF4500'
                            }}
                        >
                            Jeff's Business
                        </Typography>
                        <Typography
                            variant="h2"
                            sx={{
                                fontSize: '2.5rem',
                                fontWeight: 'bold',
                            }}
                        >
                            <animated.span>
                                {numberAnimation2.number.to((n) => `$${(n / 1000000).toFixed(1)}M`)}
                            </animated.span>
                        </Typography>
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
}

export default AnimatedNumbers;
