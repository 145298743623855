// NotFound.js

import React from 'react';
import { Box, Typography, Button } from '@mui/material';
import { Link } from 'react-router-dom';

function NotFound() {
    return (
        <Box
            sx={{
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                background: 'linear-gradient(180deg, #000000 0%, #1a1a1a 100%)',
                padding: '2rem',
            }}
        >
            <Typography
                variant="h1"
                component="h1"
                gutterBottom
                sx={{
                    fontSize: { xs: '3rem', md: '4rem' },
                    fontWeight: 'bold',
                    background: 'linear-gradient(90deg, #FF4500, #FF6347)',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    mb: '1.5rem',
                }}
            >
                404 - Page Not Found
            </Typography>

            <Typography
                variant="h6"
                component="p"
                sx={{ color: '#FFFFFF', fontSize: { xs: '1.2rem', md: '1.5rem' }, marginBottom: '2rem' }}
            >
                Sorry, the page you're looking for doesn't exist.
            </Typography>

            <Link to="/" style={{ textDecoration: 'none' }}>
                <Button
                    variant="contained"
                    sx={{
                        padding: '1rem 2rem',
                        fontSize: '1.2rem',
                        background: 'linear-gradient(90deg, #FF4500, #FF6347)',
                        color: '#FFFFFF',
                        borderRadius: '10px',
                        boxShadow: '0px 4px 20px rgba(255, 69, 0, 0.8)',
                    }}
                >
                    Go Back to Home
                </Button>
            </Link>
        </Box>
    );
}

export default NotFound;
