import React, { useRef, useState } from 'react';
import { Box, Typography, Container, TextField, Button, Card, CardContent, Grid } from '@mui/material';
import emailjs from 'emailjs-com';
import Footer from './Footer';

function SignUp() {
  const form = useRef();
  const [isSubmitting, setIsSubmitting] = useState(false); // Track form submission status
  const [error, setError] = useState(''); // Track validation errors

  // Validate form inputs
  const validateForm = (firstName, lastName, email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email regex
    if (!firstName || !lastName) {
      return 'Please enter both your first and last names.';
    }
    if (!email || !emailRegex.test(email)) {
      return 'Please enter a valid email address.';
    }
    return '';
  };

  const sendEmail = (e) => {
    e.preventDefault();

    const firstName = form.current.first_name.value;
    const lastName = form.current.last_name.value;
    const email = form.current.user_email.value;

    // Validate inputs
    const validationError = validateForm(firstName, lastName, email);
    if (validationError) {
      setError(validationError);
      return;
    }

    setError(''); // Clear any previous errors
    setIsSubmitting(true); // Disable further submissions

    // Send email to the user
    emailjs.sendForm('service_1wj76ub', 'template_o1b2jcl', form.current, 'xvf4bbglNTghv7gkk')
      .then(
        (result) => {
          console.log(result.text);
          alert('Your free report has been sent!');
        },
        (error) => {
          console.log(error.text);
          alert('Failed to send the email. Please try again.');
        }
      );

    // Send email to yourself
    emailjs.sendForm('service_1wj76ub', 'template_x60mh5q', form.current, 'xvf4bbglNTghv7gkk')
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );

    // Re-enable submission after 30 seconds
    setTimeout(() => {
      setIsSubmitting(false);
    }, 5000); // 30-second rate limit
  };

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        background: 'linear-gradient(180deg, #000000 0%, #1a1a1a 100%)',
        paddingTop: '6rem',
      }}
    >
      <Container maxWidth="sm" sx={{ textAlign: 'center', zIndex: 2, flex: 1 }}>
        {/* Header */}
        <Typography
          variant="h2"
          component="h1"
          gutterBottom
          sx={{
            fontSize: { xs: '2.5rem', md: '3rem' },
            fontWeight: 'bold',
            background: 'linear-gradient(90deg, #FF4500, #FF6347)',
            WebkitBackgroundClip: 'text',
            WebkitTextFillColor: 'transparent',
            mb: { xs: '1rem', md: '1.5rem' },
          }}
        >
          See For Yourself.
        </Typography>

        {/* Description */}
        <Typography
          variant="h6"
          component="p"
          sx={{
            color: '#FFFFFF',
            fontSize: { xs: '1rem', md: '1.2rem' },
            marginBottom: '2rem',
          }}
        >
          Sign up now to get exclusive access to the tools you need to grow your business.
        </Typography>

        {/* Show validation error */}
        {error && (
          <Typography color="error" sx={{ marginBottom: '1rem' }}>
            {error}
          </Typography>
        )}

        {/* Sign-Up Form */}
        <Card
          sx={{
            backgroundColor: '#1a1a1a',
            padding: { xs: '2rem', md: '3rem' },
            borderRadius: '12px',
            boxShadow: '0px 4px 20px rgba(255, 69, 0, 0.8)',
            border: '1px solid transparent',
            backgroundImage: 'linear-gradient(#1a1a1a, #1a1a1a), linear-gradient(90deg, #FF4500, #FF6347)',
            backgroundOrigin: 'border-box',
            backgroundClip: 'padding-box, border-box',
          }}
        >
          <CardContent>
            <form ref={form} onSubmit={sendEmail}>
              <Grid container spacing={3}>
                {/* First Name Field */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="First Name"
                    name="first_name"
                    variant="outlined"
                    fullWidth
                    sx={{
                      input: { color: 'black' },
                      backgroundColor: '#FFFFFF',
                      borderRadius: '5px',
                      boxShadow: '0px 4px 10px rgba(255, 69, 0, 0.5)',
                    }}
                  />
                </Grid>

                {/* Last Name Field */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Last Name"
                    name="last_name"
                    variant="outlined"
                    fullWidth
                    sx={{
                      input: { color: 'black' },
                      backgroundColor: '#FFFFFF',
                      borderRadius: '5px',
                      boxShadow: '0px 4px 10px rgba(255, 69, 0, 0.5)',
                    }}
                  />
                </Grid>

                {/* Email Field */}
                <Grid item xs={12}>
                  <TextField
                    label="Email"
                    name="user_email"
                    type="email"
                    variant="outlined"
                    fullWidth
                    sx={{
                      input: { color: 'black' },
                      backgroundColor: '#FFFFFF',
                      borderRadius: '5px',
                      boxShadow: '0px 4px 10px rgba(255, 69, 0, 0.5)',
                    }}
                  />
                </Grid>

                {/* Submit Button */}
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    fullWidth
                    disabled={isSubmitting} // Disable button when submitting
                    sx={{
                      padding: '1rem',
                      fontSize: '1.2rem',
                      background: isSubmitting
                        ? 'linear-gradient(90deg, grey, grey)' // Grey when submitting
                        : 'linear-gradient(90deg, #FF4500, #FF6347)',
                      color: '#FFFFFF',
                      transition: 'transform 0.3s ease',
                      '&:hover': {
                        transform: isSubmitting ? 'none' : 'scale(1.05)',
                        boxShadow: isSubmitting ? 'none' : '0px 4px 25px rgba(255, 69, 0, 0.8)',
                      },
                    }}
                  >
                    {isSubmitting ? 'Please Wait...' : 'Sign Up'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </CardContent>
        </Card>
      </Container>

      {/* Footer */}
      <Footer />
    </Box>
  );
}

export default SignUp;
