import React from 'react';
import { Box, Typography } from '@mui/material';
import { useSpring, animated } from '@react-spring/web';
import { useInView } from 'react-intersection-observer'; // Import useInView for detecting when element is in view

function KeyStepsSection() {
    const { ref: stepRef, inView } = useInView({
        triggerOnce: true, // Ensure animation runs only once when in view
        threshold: 0.2,    // Start animation when 20% of the component is in view
    });

    // Define animations that will only trigger when inView is true
    const fadeAnimation1 = useSpring({
        opacity: inView ? 1 : 0,
        transform: inView ? 'translateY(0)' : 'translateY(20px)',
        delay: 500,
        config: { duration: 800 },
    });

    const fadeAnimation2 = useSpring({
        opacity: inView ? 1 : 0,
        transform: inView ? 'translateY(0)' : 'translateY(20px)',
        delay: 1000,
        config: { duration: 800 },
    });

    const fadeAnimation3 = useSpring({
        opacity: inView ? 1 : 0,
        transform: inView ? 'translateY(0)' : 'translateY(20px)',
        delay: 1500,
        config: { duration: 800 },
    });

    const fadeAnimation4 = useSpring({
        opacity: inView ? 1 : 0,
        transform: inView ? 'translateY(0)' : 'translateY(20px)',
        delay: 2000,
        config: { duration: 800 },
    });

    const fadeAnimation5 = useSpring({
        opacity: inView ? 1 : 0,
        transform: inView ? 'translateY(0)' : 'translateY(20px)',
        delay: 2500,
        config: { duration: 800 },
    });

    const fadeAnimation6 = useSpring({
        opacity: inView ? 1 : 0,
        transform: inView ? 'translateY(0)' : 'translateY(20px)',
        delay: 3000,
        config: { duration: 800 },
    });

    const fadeAnimation7 = useSpring({
        opacity: inView ? 1 : 0,
        transform: inView ? 'translateY(0)' : 'translateY(20px)',
        delay: 3500,
        config: { duration: 800 },
    });

    return (
        <Box
            sx={{
                padding: { xs: '3rem 1rem', md: '4rem 2rem' },
                textAlign: 'center',
                color: '#FFFFFF',
                fontFamily: 'Poppins, sans-serif',
                overflow: 'hidden',
            }}
            ref={stepRef} // Attach the ref here for observing this component
        >
            {/* Section Title */}
            <Typography
                variant="h3"
                sx={{
                    color: '#FF4500',
                    marginBottom: '1.5rem',
                    fontSize: { xs: '2.5rem', md: '3.5rem' },
                    fontWeight: 'bold',
                }}
            >
                The 7 Key Steps to Business Growth
            </Typography>

            {/* Sequentially animated steps */}
            <Box sx={{ marginTop: '2rem' }}>
                <animated.div style={fadeAnimation1}>
                    <Typography
                        variant="h4"
                        sx={{
                            fontSize: { xs: '1.5rem', md: '2rem' },
                            fontWeight: 'bold',
                            marginBottom: '1rem',
                            textShadow: '0px 0px 10px rgba(255, 69, 0, 0.6)', // Forge-like glow effect
                            '&:hover': {
                                transform: 'scale(1.05)',
                                color: '#FFA500', // Highlight the step on hover
                            },
                        }}
                    >
                        Competitor Analysis
                    </Typography>
                </animated.div>

                <animated.div style={fadeAnimation2}>
                    <Typography
                        variant="h4"
                        sx={{
                            fontSize: { xs: '1.5rem', md: '2rem' },
                            fontWeight: 'bold',
                            marginBottom: '1rem',
                            textShadow: '0px 0px 10px rgba(255, 69, 0, 0.6)',
                            '&:hover': {
                                transform: 'scale(1.05)',
                                color: '#FFA500',
                            },
                        }}
                    >
                        Differentiation
                    </Typography>
                </animated.div>

                <animated.div style={fadeAnimation3}>
                    <Typography
                        variant="h4"
                        sx={{
                            fontSize: { xs: '1.5rem', md: '2rem' },
                            fontWeight: 'bold',
                            marginBottom: '1rem',
                            textShadow: '0px 0px 10px rgba(255, 69, 0, 0.6)',
                            '&:hover': {
                                transform: 'scale(1.05)',
                                color: '#FFA500',
                            },
                        }}
                    >
                        Visibility
                    </Typography>
                </animated.div>

                <animated.div style={fadeAnimation4}>
                    <Typography
                        variant="h4"
                        sx={{
                            fontSize: { xs: '1.5rem', md: '2rem' },
                            fontWeight: 'bold',
                            marginBottom: '1rem',
                            textShadow: '0px 0px 10px rgba(255, 69, 0, 0.6)',
                            '&:hover': {
                                transform: 'scale(1.05)',
                                color: '#FFA500',
                            },
                        }}
                    >
                        Lead Funnels
                    </Typography>
                </animated.div>

                <animated.div style={fadeAnimation5}>
                    <Typography
                        variant="h4"
                        sx={{
                            fontSize: { xs: '1.5rem', md: '2rem' },
                            fontWeight: 'bold',
                            marginBottom: '1rem',
                            textShadow: '0px 0px 10px rgba(255, 69, 0, 0.6)',
                            '&:hover': {
                                transform: 'scale(1.05)',
                                color: '#FFA500',
                            },
                        }}
                    >
                        Sales Flow
                    </Typography>
                </animated.div>

                <animated.div style={fadeAnimation6}>
                    <Typography
                        variant="h4"
                        sx={{
                            fontSize: { xs: '1.5rem', md: '2rem' },
                            fontWeight: 'bold',
                            marginBottom: '1rem',
                            textShadow: '0px 0px 10px rgba(255, 69, 0, 0.6)',
                            '&:hover': {
                                transform: 'scale(1.05)',
                                color: '#FFA500',
                            },
                        }}
                    >
                        Hiring
                    </Typography>
                </animated.div>

                <animated.div style={fadeAnimation7}>
                    <Typography
                        variant="h4"
                        sx={{
                            fontSize: { xs: '1.5rem', md: '2rem' },
                            fontWeight: 'bold',
                            marginBottom: '1rem',
                            textShadow: '0px 0px 10px rgba(255, 69, 0, 0.6)',
                            '&:hover': {
                                transform: 'scale(1.05)',
                                color: '#FFA500',
                            },
                        }}
                    >
                        Financial Clarity
                    </Typography>
                </animated.div>
            </Box>
        </Box>
    );
}

export default KeyStepsSection;
