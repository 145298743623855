import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#6C63FF', // Mint green for buttons
    },
    secondary: {
      main: '#10B981', // For particle glow and CTA button
    },
    background: {
      default: 'linear-gradient(180deg, #240846 0%, #301460 100%)', // Slightly brighter top purple, still dark
    },
    text: {
      primary: '#FFFFFF', // White text
      secondary: '#9CA3AF', // Light gray for descriptions
    },
  },
  typography: {
    fontFamily: ['Poppins', 'Roboto', 'sans-serif'].join(','),
    h1: {
      fontSize: '3rem',
      fontWeight: 700,
    },
    h2: {
      fontSize: '1.5rem',
      fontWeight: 400,
    },
    button: {
      textTransform: 'none',
      fontWeight: 600,
    },
  },
});

export default theme;
