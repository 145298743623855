import React from 'react';
import { Container, Box, Typography } from '@mui/material';

function TermsAndConditions() {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        padding: { xs: '4rem 1rem 2rem', sm: '5rem 2rem', md: '6rem 3rem' }, // Responsive padding for different screen sizes
        background: 'linear-gradient(180deg, #0d0d0d 0%, #1a1a1a 100%)', // Dark forge-themed background
        color: '#FF6347', // Bright orange text color
      }}
    >
      <Container maxWidth="md">
        <Typography
          variant="h1"
          gutterBottom
          sx={{
            mb: '2rem',
            color: '#FF4500', // Fiery orange for headers
            fontWeight: 'bold',
            textAlign: 'center',
            fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' } // Responsive font sizes for heading
          }}
        >
          Terms and Conditions
        </Typography>

        <Typography variant="body1" paragraph sx={{ color: '#FFFFFF', fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem' } }}>
          These Terms and Conditions govern your use of our website. By accessing or using our website, you agree to be bound by these Terms and Conditions.
        </Typography>

        <Typography
          variant="h4"
          gutterBottom
          sx={{
            mt: '2rem',
            color: '#FF4500', // Use orange for section titles
            fontWeight: 'bold',
            fontSize: { xs: '1.5rem', sm: '1.8rem', md: '2rem' } // Responsive font sizes for section titles
          }}
        >
          Use of Our Website
        </Typography>

        <Typography variant="body1" paragraph sx={{ color: '#FFFFFF', fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem' } }}>
          You agree to use our website in a manner consistent with all applicable laws and regulations. You agree not to use our website to distribute spam or other unauthorized content.
        </Typography>

        <Typography
          variant="h4"
          gutterBottom
          sx={{
            mt: '2rem',
            color: '#FF4500',
            fontWeight: 'bold',
            fontSize: { xs: '1.5rem', sm: '1.8rem', md: '2rem' }
          }}
        >
          Intellectual Property
        </Typography>

        <Typography variant="body1" paragraph sx={{ color: '#FFFFFF', fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem' } }}>
          All content on this website, including text, graphics, logos, and images, is the property of Willowark and is protected by copyright laws.
        </Typography>

        <Typography
          variant="h4"
          gutterBottom
          sx={{
            mt: '2rem',
            color: '#FF4500',
            fontWeight: 'bold',
            fontSize: { xs: '1.5rem', sm: '1.8rem', md: '2rem' }
          }}
        >
          Disclaimer
        </Typography>

        <Typography variant="body1" paragraph sx={{ color: '#FFFFFF', fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem' } }}>
          The information provided on this website is for general informational purposes only. We make no warranties regarding the accuracy or completeness of the content.
        </Typography>

        <Typography
          variant="h4"
          gutterBottom
          sx={{
            mt: '2rem',
            color: '#FF4500',
            fontWeight: 'bold',
            fontSize: { xs: '1.5rem', sm: '1.8rem', md: '2rem' }
          }}
        >
          Limitation of Liability
        </Typography>

        <Typography variant="body1" paragraph sx={{ color: '#FFFFFF', fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem' } }}>
          In no event shall Willowark be liable for any damages arising from the use of our website.
        </Typography>

        <Typography
          variant="h4"
          gutterBottom
          sx={{
            mt: '2rem',
            color: '#FF4500',
            fontWeight: 'bold',
            fontSize: { xs: '1.5rem', sm: '1.8rem', md: '2rem' }
          }}
        >
          Changes to These Terms
        </Typography>

        <Typography variant="body1" paragraph sx={{ color: '#FFFFFF', fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem' } }}>
          We reserve the right to modify these Terms and Conditions at any time. Your continued use of the website following any changes indicates your acceptance of the new terms.
        </Typography>

        <Typography
          variant="h4"
          gutterBottom
          sx={{
            mt: '2rem',
            color: '#FF4500',
            fontWeight: 'bold',
            fontSize: { xs: '1.5rem', sm: '1.8rem', md: '2rem' }
          }}
        >
          Contact Us
        </Typography>

        <Typography variant="body1" paragraph sx={{ color: '#FFFFFF', fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem' } }}>
          If you have any questions about these Terms and Conditions, please contact us at info@willowark.com.
        </Typography>
      </Container>
    </Box>
  );
}

export default TermsAndConditions;
