import React, { useEffect } from 'react';
import { Box, Typography, Container, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import stocksImage from './assets/stocks-image.webp';
import Footer from './Footer';
import KeyStepsSection from './KeyStepsSection';
import AnimatedNumbers from './AnimatedNumbers';
import AOS from 'aos';
import 'aos/dist/aos.css';

function Home() {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration in milliseconds
      once: true,     // Make sure animations only occur once
      offset: 200,    // Delays animation start by 200px before element is in view
    });
  }, []);

  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        background: 'linear-gradient(180deg, #000000 0%, #1a1a1a 100%)',
        overflow: 'hidden',
        paddingTop: { xs: '56px', md: '64px' },
      }}
    >
      {/* Hero Section */}
      <Box
        sx={{
          display: 'flex',
          flexDirection: { xs: 'column', md: 'row' },
          justifyContent: 'space-between',
          alignItems: 'center',
          minHeight: '100vh',
          padding: { xs: '2.5rem 1rem', md: '4rem 2rem' },
          position: 'relative',
        }}
      >
        {/* Ember Particles */}
        {[...Array(12)].map((_, i) => (
          <div key={i} className="ember" />
        ))}

        {/* Left: Content */}
        <Container
          maxWidth="md"
          sx={{
            textAlign: { xs: 'center', md: 'left' },
            zIndex: 2,
            padding: { xs: '0 1rem', sm: '0 2rem' } // Adjust padding for mobile alignment
          }}
        >
          <Typography
            variant="h1"
            sx={{
              fontSize: { xs: '2.2rem', sm: '3rem', md: '3rem' },
              fontWeight: 'bold',
              background: 'linear-gradient(90deg, #FF4500, #FFA500)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              mb: { xs: '1rem', md: '1.5rem' },
              lineHeight: { xs: '1.2', sm: '1.3' } // Fix line height for mobile
            }}
          >
            Our Clients Have Scaled to Millions
          </Typography>

          <Typography
            variant="h5"
            component="div"
            gutterBottom
            sx={{
              color: '#FF4500',
              fontSize: { xs: '1rem', sm: '1.2rem', md: '1.6rem' },
              maxWidth: '600px',
              marginBottom: '1.5rem',
              lineHeight: { xs: '1.4', sm: '1.5' }, // Improve readability on mobile
            }}
          >
            <strong>Effortlessly scale</strong> your business with our automated platform.
            <Box sx={{ height: '1rem' }} />
            <ul style={{ listStyleType: 'none', padding: 0, margin: 0 }}>
              <li>
                <Typography
                  variant="body1"
                  sx={{ color: '#FFFFFF', fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' } }}
                >
                  • Receive a <strong>custom digital strategy</strong> tailored to your <strong>market</strong> and <strong>competitive landscape</strong>.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  sx={{ color: '#FFFFFF', fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' } }}
                >
                  • Discover the secret to <strong>hiring</strong> and <strong>budgeting for growth</strong>.
                </Typography>
              </li>
              <li>
                <Typography
                  variant="body1"
                  sx={{ color: '#FFFFFF', fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' } }}
                >
                  • <strong>Automatically generate high-quality leads</strong> to grow your business and <strong>drive up close rates</strong>.
                </Typography>
              </li>
            </ul>
          </Typography>

          {/* Early Access Button */}
          <Box sx={{ mt: 4 }}>
            <Link to="/signup" style={{ textDecoration: 'none' }}>
              <Button
                variant="contained"
                sx={{
                  padding: { xs: '0.8rem 1.5rem', md: '1rem 2rem' },
                  fontSize: { xs: '1rem', md: '1.2rem' },
                  backgroundColor: '#FF4500',
                  color: '#FFFFFF',
                  borderRadius: '10px',
                  boxShadow: '0px 4px 20px rgba(255, 69, 0, 0.6)',
                  transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                  '&:hover': {
                    transform: 'scale(1.05)',
                    backgroundColor: '#FF6347',
                    boxShadow: '0px 6px 25px rgba(255, 69, 0, 0.8)',
                  },
                }}
              >
                Get Early Access
              </Button>
            </Link>
            <Typography
              variant="body1"
              sx={{ color: '#FFFFFF', fontSize: { xs: '0.8rem', sm: '0.9rem', md: '1rem' } }}
            >
            </Typography>
          </Box>
        </Container>

        {/* Right: Stock Image */}
        <Box
          sx={{
            width: { xs: '100%', md: '50%' },
            display: 'flex',
            justifyContent: 'center',
            animation: 'bounce 2s ease-in-out infinite',
            zIndex: 1,
            marginTop: { xs: '4rem', md: 0 }, // Add margin for mobile
          }}
        >
          <img
            src={stocksImage}
            alt="Stock Growth"
            style={{
              width: '100%',
              maxWidth: '400px',
              objectFit: 'contain',
              filter: 'drop-shadow(0px 10px 20px rgba(255, 69, 0, 0.3))',
            }}
          />
        </Box>
      </Box>

      {/* Copy Section */}
      <Box
        sx={{
          padding: { xs: '3rem 1rem', md: '4rem 2rem' }, // Responsive padding for copy section
          color: '#ffffff',
          position: 'relative',
        }}
      >
        {/* Title */}
        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: '1.8rem', sm: '2.2rem', md: '2.8rem' }, // Scale text for mobile
            fontWeight: 'bold',
            color: '#FFFFFF',
            marginBottom: '2rem',
            textAlign: 'left',
          }}
          data-aos="fade-up"
        >
          SEO and ad agencies promise to grow your business... but isn’t lead generation only a small piece of real growth?
        </Typography>

        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: '1.8rem', sm: '2.2rem', md: '2.8rem' }, // Responsive text for mobile
            fontWeight: 'bold',
            color: '#FFFFFF',
            marginBottom: '2rem',
            textAlign: 'left',
          }}
          data-aos="fade-up"
        >
          They’re not the solution, and it’s time to stop believing they are.
        </Typography>

        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: '1.8rem', sm: '2.2rem', md: '2.8rem' }, // Responsive text for mobile
            fontWeight: 'bold',
            color: '#FFFFFF',
            marginBottom: '2rem',
            textAlign: 'left',
          }}
          data-aos="fade-up"
        >
          So, what’s the alternative?
        </Typography>

        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: '1.8rem', sm: '2.2rem', md: '2.8rem' }, // Responsive text for mobile
            fontWeight: 'bold',
            color: '#FFFFFF',
            marginBottom: '2rem',
            textAlign: 'left',
          }}
          data-aos="fade-up"
        >
          A team of experienced people who’ve scaled their own service businesses into multi-million-dollar operations.
        </Typography>

        <AnimatedNumbers />

        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: '1.8rem', sm: '2.2rem', md: '2.8rem' }, // Responsive text for mobile
            fontWeight: 'bold',
            color: '#FFFFFF',
            marginBottom: '2rem',
            textAlign: 'left',
          }}
          data-aos="fade-up"
        >
          We know exactly what it takes to become a giant in your market—but only if you’re serious about doing the work.
        </Typography>

        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: '1.8rem', sm: '2.2rem', md: '2.8rem' }, // Responsive text for mobile
            fontWeight: 'bold',
            color: '#FFFFFF',
            marginBottom: '2rem',
            textAlign: 'left',
          }}
          data-aos="fade-up"
        >
          This isn’t luck—it’s strategy, process, and execution. And I’m giving you the 7 key steps as a shameless bribe—because once you learn them, I know you’ll trust us to help you scale fast.
        </Typography>

        <KeyStepsSection />

        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: '1.8rem', sm: '2.2rem', md: '2.8rem' }, // Responsive text for mobile
            fontWeight: 'bold',
            color: '#FFFFFF',
            marginBottom: '2rem',
            textAlign: 'left',
          }}
          data-aos="fade-up"
        >
          We built these 7 steps into Service Business Forge—a first-of-its-kind platform that doesn’t just teach them but automates them, so you live them every day.
        </Typography>

        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: '1.8rem', sm: '2.2rem', md: '2.8rem' }, // Responsive text for mobile
            fontWeight: 'bold',
            color: '#FFFFFF',
            marginBottom: '2rem',
            textAlign: 'left',
          }}
          data-aos="fade-up"
        >
          But let me be clear—we only work with business owners as serious about growth as we are.
        </Typography>

        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: '1.8rem', sm: '2.2rem', md: '2.8rem' }, // Responsive text for mobile
            fontWeight: 'bold',
            color: '#FFFFFF',
            marginBottom: '2rem',
            textAlign: 'left',
          }}
          data-aos="fade-up"
        >
          If you've read this far, you have the potential to grow a multi-million dollar business.
        </Typography>

        <Typography
          variant="h2"
          sx={{
            fontSize: { xs: '1.8rem', sm: '2.2rem', md: '2.8rem' }, // Responsive text for mobile
            fontWeight: 'bold',
            color: '#FFFFFF',
            marginBottom: '2rem',
            textAlign: 'center',
          }}
          data-aos="fade-up"
        >
          I want to invest in you. Click the button below to get a free report containing detailed information on the 7 key steps to business growth.
        </Typography>

        {/* Free Report Button */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center', // Horizontally centers the button
            marginTop: '2rem',
          }}
          data-aos="fade-up"
        >
          <Link to="/signup" style={{ textDecoration: 'none' }}>
            <Button
              variant="contained"
              sx={{
                padding: { xs: '0.8rem 1.5rem', md: '1rem 2rem' }, // Adjust padding for mobile
                fontSize: { xs: '1rem', md: '1.2rem' },
                backgroundColor: '#FF4500',
                color: '#FFFFFF',
                borderRadius: '10px',
                boxShadow: '0px 4px 20px rgba(255, 69, 0, 0.6)',
                transition: 'transform 0.3s ease, box-shadow 0.3s ease',
                '&:hover': {
                  transform: 'scale(1.05)',
                  backgroundColor: '#FF6347',
                  boxShadow: '0px 6px 25px rgba(255, 69, 0, 0.8)',
                },
              }}
            >
              Get My Report
            </Button>
          </Link>
        </Box>
      </Box>

      {/* Footer */}
      <Footer />
    </Box>
  );
}

export default Home;
