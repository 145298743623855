import React from 'react';
import { Box, Container, Grid, Typography, Link } from '@mui/material';

function Footer() {
  return (
    <Box
      sx={{
        backgroundColor: '#0d0d0d',  // Deep black for the footer
        padding: '3rem 0',  // Added padding for better spacing
        color: '#FF6347',  // Consistent orange text color throughout the footer
        textAlign: 'center',
        mt: 9,
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={4} sx={{ justifyContent: 'space-between', alignItems: 'center' }}>

          {/* Logo and Tagline */}
          <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}> {/* Center the content */}
            <Typography variant="h5" sx={{ fontWeight: 'bold', color: '#FFFFFF' }}> {/* White for the logo */}
              Willowark
            </Typography>
            <Typography variant="body2" sx={{ color: '#FFFFFF', mt: 1 }}> {/* Slightly darker orange for tagline */}
              Trust Us With Your Business Growth
            </Typography>
          </Grid>

          {/* Quick Links */}
          <Grid item xs={12} md={4}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2, color: '#FFFFFF' }}> {/* Consistent heading color */}
              Quick Links
            </Typography>
            <Link href="/" underline="none" sx={{ color: '#FFFFFF', display: 'block', mb: 1 }}> {/* Consistent bright orange links */}
              Home
            </Link>
            <Link href="/signup" underline="none" sx={{ color: '#FFFFFF', display: 'block', mb: 1 }}> {/* Consistent bright orange links */}
              Sign Up
            </Link>
          </Grid>

          {/* Contact Information */}
          <Grid item xs={12} md={4} sx={{ textAlign: 'center' }}> {/* Center the contact info */}
            <Typography variant="h6" sx={{ fontWeight: 'bold', color: '#FFFFFF' }}> {/* White for heading */}
              Contact Us
            </Typography>
            <Typography variant="body2" sx={{ color: '#FFFFFF' }}> {/* Bright orange for email */}
              Email: <Link href="mailto:info@willowark.com" sx={{ color: '#FFFFFF', textDecoration: 'none' }}>info@willowark.com</Link>
            </Typography>
            <Typography variant="body2" sx={{ mt: 1, color: '#FFFFFF' }}> {/* Darker orange for phone */}
              Phone: (518) 545-8864
            </Typography>
          </Grid>

        </Grid>

        {/* Footer Bottom Text */}
        <Box sx={{ mt: 4, paddingTop: '1rem' }}>  {/* Add subtle top border */}
          <Typography variant="body2" sx={{ color: '#FFFFFF', fontSize: '0.9rem' }}> {/* Consistent footer text */}
            © {new Date().getFullYear()} Willowark. All Rights Reserved.
          </Typography>
          <Box sx={{ display: 'inline-flex', gap: 2, mt: 1 }}>
            <Link href="/privacy" underline="none" sx={{ color: '#FFFFFF', fontSize: '0.9rem' }}> {/* Bright orange for legal links */}
              Privacy Policy
            </Link>
            <Link href="/terms" underline="none" sx={{ color: '#FFFFFF', fontSize: '0.9rem' }}>
              Terms of Service
            </Link>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default Footer;
