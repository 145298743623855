import React from 'react';
import { Container, Box, Typography } from '@mui/material';

function PrivacyPolicy() {
  return (
    <Box
      sx={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        padding: { xs: '4rem 1rem 2rem', sm: '5rem 2rem', md: '6rem 3rem' },  // Adjusted padding for different screen sizes
        background: 'linear-gradient(180deg, #0d0d0d 0%, #1a1a1a 100%)',
        color: '#FF6347',  // Bright orange text color for readability
      }}
    >
      <Container maxWidth="md">
        <Typography
          variant="h1"
          gutterBottom
          sx={{
            mb: '2rem',
            color: '#FF4500',
            fontWeight: 'bold',
            textAlign: 'center',
            fontSize: { xs: '2rem', sm: '2.5rem', md: '3rem' }  // Responsive font size for heading
          }}
        >
          Privacy Policy
        </Typography>

        <Typography variant="body1" paragraph sx={{ color: '#FFFFFF', fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem' } }}>
          This Privacy Policy describes how Willowark ("we," "us," or "our") collects, uses, and shares your personal information when you visit and use our website.
        </Typography>

        <Typography
          variant="h4"
          gutterBottom
          sx={{
            mt: '2rem',
            color: '#FF4500',
            fontWeight: 'bold',
            fontSize: { xs: '1.3rem', sm: '1.8rem', md: '2rem' }  // Responsive font size for subheadings
          }}
        >
          Information We Collect
        </Typography>

        <Typography variant="body1" paragraph sx={{ color: '#FFFFFF', fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem' } }}>
          We collect the personal information that you voluntarily provide to us when you sign up for our email list, including your email address and any other contact information you provide. We use this information to send you updates, promotions, and other marketing communications.
        </Typography>

        <Typography
          variant="h4"
          gutterBottom
          sx={{
            mt: '2rem',
            color: '#FF4500',
            fontWeight: 'bold',
            fontSize: { xs: '1.3rem', sm: '1.8rem', md: '2rem' }
          }}
        >
          How We Use Your Information
        </Typography>

        <Typography variant="body1" paragraph sx={{ color: '#FFFFFF', fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem' } }}>
          We may use your personal information to:
          <ul style={{ color: '#FFFFFF' }}>
            <li>Send you promotional emails about our products and services</li>
            <li>Respond to your inquiries or requests</li>
            <li>Improve the user experience on our website</li>
          </ul>
        </Typography>

        <Typography
          variant="h4"
          gutterBottom
          sx={{
            mt: '2rem',
            color: '#FF4500',
            fontWeight: 'bold',
            fontSize: { xs: '1.3rem', sm: '1.8rem', md: '2rem' }
          }}
        >
          Sharing Your Information
        </Typography>

        <Typography variant="body1" paragraph sx={{ color: '#FFFFFF', fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem' } }}>
          We do not sell or rent your personal information to third parties. However, we may share your information with trusted third-party service providers to help us manage and optimize our email marketing efforts.
        </Typography>

        <Typography
          variant="h4"
          gutterBottom
          sx={{
            mt: '2rem',
            color: '#FF4500',
            fontWeight: 'bold',
            fontSize: { xs: '1.3rem', sm: '1.8rem', md: '2rem' }
          }}
        >
          Your Choices
        </Typography>

        <Typography variant="body1" paragraph sx={{ color: '#FFFFFF', fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem' } }}>
          You can unsubscribe from our email list at any time by clicking the "unsubscribe" link in any of our emails. You may also contact us directly to request removal from our list.
        </Typography>

        <Typography
          variant="h4"
          gutterBottom
          sx={{
            mt: '2rem',
            color: '#FF4500',
            fontWeight: 'bold',
            fontSize: { xs: '1.3rem', sm: '1.8rem', md: '2rem' }
          }}
        >
          Contact Us
        </Typography>

        <Typography variant="body1" paragraph sx={{ color: '#FFFFFF', fontSize: { xs: '0.9rem', sm: '1rem', md: '1.1rem' } }}>
          If you have any questions about this Privacy Policy, please contact us at info@willowark.com.
        </Typography>
      </Container>
    </Box>
  );
}

export default PrivacyPolicy;
