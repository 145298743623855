import React, { useState, useEffect } from 'react';
import { AppBar, Toolbar, Button, Box } from '@mui/material';
import { Link } from 'react-router-dom';
import companyLogo from './assets/logo.png'; // Adjust the path if needed

function Navbar() {
  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    const offset = window.scrollY;
    if (offset > 100) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <AppBar
      position="fixed"
      sx={{
        backgroundColor: scrolled ? 'rgba(0, 0, 0, 0.8)' : 'transparent',
        boxShadow: scrolled ? '0px 4px 10px rgba(0, 0, 0, 0.5)' : 'none',
        transition: 'background-color 0.3s ease, box-shadow 0.3s ease',
      }}
    >
      <Toolbar sx={{ justifyContent: 'space-between' }}>
        {/* Left: Company Logo */}
        <Link to="/" style={{ textDecoration: 'none' }}>
          <img 
            src={companyLogo} 
            alt="Company Logo" 
            style={{ 
              height: '50px', // Adjust the size as needed
              width: 'auto', 
              display: 'block' 
            }} 
          />
        </Link>

        {/* Center: Navigation Links */}
        <Box sx={{ display: 'flex', gap: 3 }}>
          {/*
          <Button component={Link} to="/pricing" color="inherit">
            Pricing
          </Button>
          <Button component={Link} to="/product" color="inherit">
            Product
          </Button>
          <Button component={Link} to="/company" color="inherit">
            Company
          </Button>
          */}
          <Button component={Link} to="/" color="inherit">
            Fast-Track Your Business
          </Button>
        </Box>

        {/* Right: Join Now Button */}
        {/* <Button
          component={Link}
          to="/signup"
          variant="contained"
          color="secondary"
          sx={{
            backgroundColor: '#10B981',
            color: 'white',
            fontWeight: 'bold',
            boxShadow: '0px 4px 10px rgba(16, 185, 129, 0.6)',
          }}
        >
          Join Now
        </Button> */}
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
