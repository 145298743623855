import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { Box } from '@mui/material';
import theme from './theme';
import Navbar from './Navbar';
import SignUp from './SignUp';
import TermsAndConditions from './Terms';
import PrivacyPolicy from './Privacy';
import Home from './Home'; 
import NotFound from './NotFound';

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Box sx={{ background: theme.palette.background.default, minHeight: '100vh' }}>
          {/* Navigation Bar */}
          <Navbar />

          {/* Main Content Area */}
          <Routes>
            <Route path="/" element={<Home />} />
            {/* <Route path="/pricing" element={<Pricing />} /> */}
            {/* <Route path="/product" element={<Product />} /> */}
            {/* <Route path="/company" element={<Company />} /> */}
            <Route path="/signup" element={<SignUp />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/terms" element={<TermsAndConditions />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </Box>
      </Router>
    </ThemeProvider>
  );
}

export default App;
